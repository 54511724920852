/**
 * Edit prices & pricelists
 */

// allow only one active pricelist
$('.pricelists input[type=checkbox]').on('click', function () {
    if($(this).prop('checked')) {
        var enabled = this;
        $('.pricelists input[type=checkbox]').each(function () {
            if($(this).attr('name') != $(enabled).attr('name')) {
                $(this).prop('checked', false);
            }
        });
    }
});

// disable the modal buttons
$('.pricemodal-button').click(function (evt) {
    evt.preventDefault();
});

$('.addPriceButton').click(function (evt) {
    evt.preventDefault();
    var id = $(this).data('listid');

    $.ajax('/admin/addprice/' + id).done(function (res) {
        $('#prices-' + id).append(res);
    });
});

$('.deletePricelist').click(function (evt) {
    evt.preventDefault();

    if(confirm('delete this pricelist?')) {
        var id = 'input[name=delete-'+$(this).data('listid')+']';
        $(id).val(1);
        $('form.pricelist-form').submit();
    }
})
